import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
}; // 重复点击路由报错的问题
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    components: {
      web: () => import("@/views/mobile/home.vue"),
      ipad: () => import("@/views/ipad/home.vue"),
      pc: () => import("@/views/pc/home.vue"),
      default: () => import("@/views/pc/home.vue"),
    },
  },
  {
    path: "/desksidea",
    components: {
      pc: () => import("@/views/pc/deskSidea.vue"),
      ipad: () => import("@/views/ipad/deskSidea.vue"),
      web: () => import("@/views/mobile/deskSidea.vue"),
    },
  },
  {
    path: "/deskabout",
    components: {
      pc: () => import("@/views/pc/deskAbout.vue"),
      ipad: () => import("@/views/ipad/deskAbout.vue"),
      web: () => import("@/views/mobile/deskAbout.vue"),
    },
  },
  {
    path: "/project",
    components: {
      pc: () => import("@/views/pc/project.vue"),
      ipad: () => import("@/views/ipad/project.vue"),
      web: () => import("@/views/mobile/project.vue"),
    },
  },
  {
    path: "/new",
    components: {
      pc: () => import("@/views/pc/new.vue"),
      ipad: () => import("@/views/ipad/new.vue"),
      web: () => import("@/views/mobile/new.vue"),
    },
  },
  {
    path: "/dome",
    components: {
      pc: () => import("@/views/dome/dome.vue"),
    },
  },
  {
    path: "/side",
    components: {
      pc: () => import("@/views/pc/side.vue"),
      web: () => import("@/views/mobile/side.vue"),
      ipad: () => import("@/views/ipad/side.vue"),
    },
  },
  // {
  //   path: "/index",
  //   components: {
  //     pc: () => import("@/views/dome/index.vue"),
  //   },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
