<template>
  <div id="app">
    <router-view v-if="showPage == 1" name="web" >web</router-view>
    <router-view v-if="showPage == 2" name="ipad" >ipad</router-view>
    <router-view v-if="showPage == 3" name="pc" >pc</router-view>
  </div>
</template>


<script>
export default {
  data() {
    return {
      showPage: 1
    };
  },
  created() {
    this._isMobile();
  },
  methods: {
    _isMobile() {
      var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet =
          /(?:iPad|PlayBook)/.test(ua) ||
          (isAndroid && !/(?:Mobile)/.test(ua)) ||
          (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
      if (isPhone || isAndroid) {
        this.showPage = 1;
        return;
      }
      if (isTablet) {
        this.showPage = 2;
        return;
      }
      if (isPc) {
        this.showPage = 3;
        return;
      }
      // return {
      //   isTablet: isTablet,
      //   isPhone: isPhone,
      //   isAndroid: isAndroid,
      //   isPc: isPc
      // };
    }
  }
};
</script>



<style lang="less">
</style>
