import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/style/pcbase.css";
import "./assets/font/font.css";
import waterfall from "vue-waterfall2"; // 瀑布流
import axios from "axios";
import Vant from "vant";
import "vant/lib/index.css";
import VueLazyload from "vue-lazyload";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// Vue.use(VueLazyload);
Vue.use(VueLazyload, {
  loading: require("./assets/img/loading.gif"),
  throttleWait: 3500,
});
Vue.prototype.lazyload = VueLazyload;

Vue.prototype.axios = axios;
axios.defaults.baseURL = "https://b.fufu.zbfib.com";
axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
Vue.prototype.getQueryValue = function(name) {
  var url = window.location.href;
  var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));
  if (result == null || result.length < 1) {
    return "";
  }
  return decodeURI(result[1]);
};
Vue.use(waterfall);
Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
